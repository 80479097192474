import React from 'react';
import { Typography, Form, Space, Row, Radio, Col } from 'antd';
import CompanyTypeSelectionHandler from 'src/components/commons/companyTypeSelection';
import { CloseCircleFilled, SearchOutlined } from '@ant-design/icons';
import CustomSelect from 'src/components/commons/customSelect';
import CustomButton from 'src/components/commons/customButton';
import useSaleTeamsStore from 'src/hooks/useSaleTeamsStore';
import StyledParentCompanyInput from 'src/components/companyDetails/components/styledParentCompanyInput';
import { CustomStyledInput } from '../createCompany.styles';
import { CreateCompanyBodyRendererTypes } from '../types';
import ParentCompanyModal from './parentCompanyModal';
import isRequired, { getOptions, parseEventFromHeader } from '../utils/functions';

const DetailsRenderer: React.FC<CreateCompanyBodyRendererTypes> = ({
  formElementsOptions,
  handleInputChange,
  dependencyCompanyTypeHandler,
  handleSelectChange,
  dependencyCompanyType,
  onRadioChangeEvent,
  handleTextAreaChange,
  expanded,
  setOpenedParentModal,
  onCloseParentModel,
  openedParentModal,
  handleMultiSelection,
  checkGroupHandler,
}) => {
  const parseEvent =
    (fromHeaderName: string) => (value: React.FormEvent<HTMLInputElement>, name?: string) => {
      const event = parseEventFromHeader(value, fromHeaderName);

      if (handleSelectChange) {
        handleSelectChange(event, name);
      }
    };

  const { queryGetAllSalesTeamOldResponse } = useSaleTeamsStore();

  const form = Form.useFormInstance();
  const parentName = Form.useWatch('parent', form);

  return (
    <div style={{ marginBottom: '49px' }}>
      <Typography.Title level={4} className='section-title'>
        Details{' '}
      </Typography.Title>
      <Row justify='space-between'>
        <Col span={expanded ? 12 : 24} style={{ paddingRight: expanded ? '5px' : 0 }}>
          <CompanyTypeSelectionHandler
            required
            {...{ dependencyCompanyTypeHandler }}
            externalHandleSelectChange={parseEvent('companyType')}
            {...{ checkGroupHandler }}
          />
        </Col>
        <Col span={expanded ? 12 : 24} style={{ paddingLeft: expanded ? '5px' : 0 }}>
          <Form.Item
            label='Sales Team'
            name='salesTeam'
            required={
              isRequired(formElementsOptions, 'salesTeam') ||
              dependencyCompanyType?.salesTeamRequired ||
              false
            }
          >
            <CustomSelect
              customClassName='customSelect'
              placeHolder='Select a Sales Team'
              handleChange={handleSelectChange}
              options={queryGetAllSalesTeamOldResponse.data?.GetAllSalesTeam?.results?.map(
                (team: { id: number; name: string }) => ({
                  label: team.name!,
                  value: team.id,
                }),
              )}
              dataTestId='sales-team-select'
              externalName='salesTeam'
              disabled={dependencyCompanyType?.salesTeamBlocked}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify='space-between'>
        {/* <Col span={expanded ? 12 : 24} style={{ paddingRight: expanded ? '5px' : 0 }}>
          <Form.Item
            label='Private Team'
            name='privateTeamId'
            required={isRequired(formElementsOptions, 'privateTeam')}
          >
            <CustomSelect
              customClassName='customSelect'
              placeHolder='Select a Private Team'
              handleChange={parseEvent('privateTeam')}
              options={privateTeams?.map((team) => ({
                label: team.name!,
                value: team.id,
              }))}
              dataTestId='private-team-select'
              externalName='privateTeamId'
            />
          </Form.Item>
        </Col> */}
        <Col span={24} style={{ paddingLeft: expanded ? '5px' : 0 }}>
          <Form.Item
            label='Parent Company'
            name='parent'
            required={isRequired(formElementsOptions, 'parent')}
          >
            <Space.Compact style={{ width: '100%' }}>
              <StyledParentCompanyInput style={{ width: '100%' }}>
                <Form.Item name='parent' noStyle>
                  <CustomStyledInput disabled />
                </Form.Item>
                {!!parentName && (
                  <span
                    className='reset-button'
                    style={{ top: 8 }}
                    onClick={() => onCloseParentModel?.(null)}
                  >
                    <CloseCircleFilled />
                  </span>
                )}
              </StyledParentCompanyInput>
              <CustomButton
                type='primary'
                icon={<SearchOutlined />}
                style={{ borderRadius: 0 }}
                onlyIcon
                dataTestId='add-new-parent-company-button'
                placement='topRight'
                tooltipColor='#CCCCCC'
                tooltipText='Choose Parent Company'
                tooltipClassName='custom-tooltip'
                onClick={() => setOpenedParentModal && setOpenedParentModal(true)}
              />
            </Space.Compact>
          </Form.Item>
        </Col>
      </Row>
      <Row justify='space-between'>
        <Col span={expanded ? 12 : 24} style={{ paddingRight: expanded ? '5px' : 0 }}>
          <Form.Item
            label='Call Pattern'
            name='callPatternId'
            required={isRequired(formElementsOptions, 'callPattern')}
          >
            <CustomSelect
              customClassName='customSelect'
              placeHolder='Select a Call Pattern'
              options={getOptions(formElementsOptions, 'callPattern')}
              handleChange={parseEvent('callPattern')}
              externalName='callPatternId'
              dataTestId='call-pattern-select'
            />
          </Form.Item>
        </Col>
        <Col span={expanded ? 12 : 24} style={{ paddingLeft: expanded ? '5px' : 0 }}>
          <Form.Item
            label='Category'
            name='categoryId'
            required={isRequired(formElementsOptions, 'category')}
          >
            <CustomSelect
              customClassName='customSelect'
              placeHolder='Select a Category'
              handleChange={parseEvent('category')}
              options={getOptions(formElementsOptions, 'category')}
              externalName='categoryId'
              dataTestId='category-select'
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify='space-between'>
        <Col span={expanded ? 8 : 24} style={{ paddingRight: expanded ? '5px' : 0 }}>
          <Form.Item label='Rank' name='rankId' required={isRequired(formElementsOptions, 'rank')}>
            <CustomSelect
              customClassName='customSelect'
              placeHolder='Select a Rank'
              handleChange={parseEvent('rank')}
              options={getOptions(formElementsOptions, 'rank')}
              externalName='rankId'
              dataTestId='rank-select'
            />
          </Form.Item>
        </Col>
        <Col span={expanded ? 8 : 24} style={{ padding: expanded ? '0 5px' : 0 }}>
          <Form.Item
            label='Visit Frequency (days)'
            name='visitFrequency'
            required={isRequired(formElementsOptions, 'visitFrequency')}
          >
            <CustomStyledInput
              min={0}
              type='number'
              onChange={handleInputChange}
              name='visitFrequency'
              data-testid='visit-frecuency-input'
            />
          </Form.Item>
        </Col>
        <Col span={expanded ? 8 : 24} style={{ paddingLeft: expanded ? '5px' : 0 }}>
          <Form.Item
            label='Print Name'
            name='printName'
            required={isRequired(formElementsOptions, 'printName')}
          >
            <CustomStyledInput
              onChange={handleInputChange}
              name='printName'
              data-testid='print-name-input'
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify='space-between' align='middle'>
        <Form.Item name='isActive' required={isRequired(formElementsOptions, 'isActive')}>
          <Row>
            <Typography.Text style={{ marginRight: 15 }}> Active </Typography.Text>
            <Radio.Group name='isActive' onChange={onRadioChangeEvent} defaultValue={1}>
              <Radio value={1} data-testid='active-yes'>
                {' '}
                Yes{' '}
              </Radio>
              <Radio value={0} data-testid='active-no'>
                {' '}
                No{' '}
              </Radio>
            </Radio.Group>
          </Row>
        </Form.Item>
        <Form.Item
          name='hasHolydayCard'
          required={isRequired(formElementsOptions, 'hasHolydayCard')}
        >
          <Row>
            <Typography.Text style={{ marginRight: 15 }}>Holiday Card</Typography.Text>
            <Radio.Group name='hasHolydayCard' onChange={onRadioChangeEvent} defaultValue={1}>
              <Radio value={1} data-testid='has-holiday-yes'>
                {' '}
                Yes{' '}
              </Radio>
              <Radio value={0} data-testid='has-holiday-no'>
                {' '}
                No{' '}
              </Radio>
            </Radio.Group>
          </Row>
        </Form.Item>
        {/* TODO this feature will be enabled inthe future */}
        <Form.Item name='hasPosAccount' required={isRequired(formElementsOptions, 'hasPosAccount')}>
          <Row>
            <Typography.Text style={{ marginRight: 15 }}> POS Account </Typography.Text>
            <Radio.Group name='hasPosAccount' onChange={onRadioChangeEvent} defaultValue={0}>
              <Radio value={1} data-testid='pos-account-yes'>
                {' '}
                Yes{' '}
              </Radio>
              <Radio value={0} data-testid='pos-account-no'>
                {' '}
                No{' '}
              </Radio>
            </Radio.Group>
          </Row>
        </Form.Item>
      </Row>
      <Form.Item label='Tags' name='tags' required={isRequired(formElementsOptions, 'tags')}>
        <CustomSelect
          customClassName='customSelect'
          placeHolder='Select Tags'
          handleMultipleSelect={handleMultiSelection}
          allowClear
          multiSel
          externalWidth='100%'
          listDefault={getOptions(formElementsOptions, 'tags')?.map((tag) => tag.label)}
          externalName='tags'
          dataTestId='tags-select'
        />
      </Form.Item>
      <Row justify='space-between' gutter={8}>
        <Col span={24}>
          <Form.Item
            label='Delivery Instructions'
            name='shipDetail'
            required={isRequired(formElementsOptions, 'shipDetail')}
          >
            <CustomStyledInput.TextArea
              rows={6}
              style={{ borderRadius: 0 }}
              onChange={handleTextAreaChange}
              name='shipDetail'
              data-testid='delivery-instruction-textarea'
            />
          </Form.Item>
        </Col>
        {/* <Col span={6}>
          <Dragger />
        </Col> */}
      </Row>

      <ParentCompanyModal show={openedParentModal!} onClose={onCloseParentModel!} />
    </div>
  );
};

export default DetailsRenderer;
