import React, { useRef } from 'react';
import { Row, Col, Form, Input, Space } from 'antd';
import CustomSelect from 'src/components/commons/customSelect';
import CustomButton from 'src/components/commons/customButton';
import { PlusOutlined } from '@ant-design/icons';
import {
  FormAddressElementsType,
  FormElementsOptions,
  AddressModel,
} from 'src/types/companiesTypes';
import isRequired, { getOptions } from '../createCompany/utils/functions';

interface CustomAddressFormType {
  addressFormatList: { value: string; label: string }[];
  deleteIcon: React.ReactNode;
  hideOrShowIcon: React.ReactNode;
  compactFormHandler?: (element: FormAddressElementsType) => void;
  elementData: FormAddressElementsType;
  addNewFormElement?: () => void;
  addressTypeAction?: (
    elementId: React.Key,
    addressType: React.FormEvent<HTMLInputElement>,
  ) => void;
  deleteAddressAction?: (elementId: React.Key) => void;
  onAddressSearch?: (searchText: HTMLInputElement, addressId: React.Key) => void;
  onInputChange?: (addressId: React.Key, data: string, formName: string) => void;
  requiredFields?: FormElementsOptions[];
  onSelectChange?: (
    addressId: React.Key,
    event: React.FormEvent<HTMLInputElement>,
    formName?: string,
  ) => void;
  addresses?: AddressModel[] | undefined;
}

const CustomAddressForm: React.FC<CustomAddressFormType> = ({
  addressFormatList,
  deleteIcon,
  hideOrShowIcon,
  compactFormHandler,
  elementData,
  addNewFormElement,
  addressTypeAction,
  deleteAddressAction,
  onAddressSearch,
  onInputChange,
  requiredFields,
  onSelectChange,
  addresses,
}) => {
  /**
   * ! This is a hack to re-render the component when the address selected
   * ! we need to fix this behavior in the future
   */
  const [, renderStatus] = React.useState(false);

  const reRender = () => {
    renderStatus((prevState) => !prevState);
  };

  const addressRef: any = useRef(null);

  /**
   * ! End of re-render hack
   */

  const addressTypeActionHandler = (e: React.FormEvent<HTMLInputElement>) => {
    const addressType = e;
    if (addressTypeAction) {
      addressTypeAction(elementData.formId, addressType);
    }
  };

  const onInputChangeHandler = (data: string, formName: string) => {
    if (onInputChange) {
      onInputChange(elementData.formId, data, formName);
    }
  };

  const onSelectChangeHandler = (e: React.FormEvent<HTMLInputElement>, name?: string) => {
    if (onSelectChange) {
      onSelectChange(elementData.formId, e, name);
    }

    reRender();
  };

  const addressSearchHandler = (e: React.FormEvent<HTMLInputElement>) => {
    if (onAddressSearch) {
      onAddressSearch(addressRef.current.input, elementData.formId);
      onInputChangeHandler(e.currentTarget.value, 'address');
    }
  };

  return (
    <Col span={24}>
      {elementData.formId !== 'form-1' && (
        <Row align='middle'>
          <CustomSelect
            style={{ width: 165, marginRight: 'auto' }}
            defaultValue='Select Address Type'
            options={addressFormatList as []}
            customClassName='no-border-radius-select'
            handleChange={addressTypeActionHandler}
            dataTestId='address-type-select'
          />
          <CustomButton
            shape='circle'
            icon={deleteIcon}
            onlyIcon
            onClick={() => {
              if (deleteAddressAction) {
                deleteAddressAction(elementData.formId);
              }
            }}
            dataTestId='delete-address-button'
          />
          <CustomButton
            shape='circle'
            icon={hideOrShowIcon}
            onClick={() => {
              if (compactFormHandler) {
                compactFormHandler(elementData);
              }
            }}
            onlyIcon
            dataTestId='sow-hide-address-button'
          />
        </Row>
      )}
      <div style={{ marginTop: elementData.formId === 'form-1' ? 15 : 2 }}>
        <Form.Item
          label={elementData.formId === 'form-1' && 'Main Address'}
          style={{ marginBottom: 15 }}
          required={
            requiredFields?.find(
              (option: FormElementsOptions) => option.name === 'mainAddress.address',
            )?.required || false
          }
        >
          <Space.Compact style={{ width: '100%' }}>
            <Input
              style={{ borderRadius: 0 }}
              data-testid='main-address'
              onChange={addressSearchHandler}
              ref={addressRef}
              id={`${elementData.formId}_address`}
              disabled={elementData.addressData.addressType === null}
              value={addresses?.[0]?.ADDRESS ?? elementData.addressData.address}
            />
            <CustomButton
              type='primary'
              icon={<PlusOutlined />}
              style={{ borderRadius: 0 }}
              onClick={addNewFormElement}
              onlyIcon
              dataTestId='add-new-address-button'
              disabled={elementData.addressData.addressType === null}
              tooltipText='Add new Address'
              tooltipColor='#CCCCCC'
              tooltipClassName='custom-tooltip'
              placement='topRight'
            />
          </Space.Compact>
        </Form.Item>
        <div style={{ display: elementData.compactForm ? 'none' : undefined }}>
          <Row justify='space-between'>
            <Col span={12}>
              <Form.Item
                label='PO Box'
                style={{ marginBottom: 15, paddingRight: 5 }}
                required={
                  requiredFields?.find(
                    (option: FormElementsOptions) => option.name === 'mainAddress.pobox',
                  )?.required || false
                }
              >
                <Input
                  style={{ borderRadius: 0 }}
                  data-testid='po-box-input'
                  name='pobox'
                  id={`${elementData.formId}_po_box`}
                  disabled={elementData.addressData.addressType === null}
                  value={addresses?.[0]?.PO_BOX ?? undefined}
                  onChange={(event) => onInputChangeHandler(event.currentTarget.value, 'pobox')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Postal Code'
                style={{ marginBottom: 15, paddingLeft: 5 }}
                required={
                  requiredFields?.find(
                    (option: FormElementsOptions) => option.name === 'mainAddress.zipCode',
                  )?.required || false
                }
              >
                <Input
                  style={{ borderRadius: 0 }}
                  data-testid='postal-code-input'
                  name='zipCode'
                  id={`${elementData.formId}_zipCode`}
                  disabled={elementData.addressData.addressType === null}
                  value={elementData.addressData.zipCode}
                  onChange={(event) => onInputChangeHandler(event.currentTarget.value, 'zipCode')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify='space-between'>
            <Col span={12}>
              <Form.Item
                label='Region'
                style={{ marginBottom: 15, paddingRight: 5 }}
                required={isRequired(requiredFields, 'mainAddress.region')}
              >
                <CustomSelect
                  style={{ borderRadius: 0 }}
                  placeHolder='Select Region'
                  options={getOptions(requiredFields, 'mainAddress.region')}
                  customClassName='no-border-radius-select'
                  dataTestId='region-select'
                  id={`${elementData.formId}_region`}
                  disabled={elementData.addressData.addressType === null}
                  handleChange={onSelectChangeHandler}
                  externalName='mainAddress.region'
                  externalValue={addresses?.[0]?.REGION ?? undefined}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Territory'
                style={{ marginBottom: 15, paddingLeft: 5 }}
                required={isRequired(requiredFields, 'mainAddress.territory')}
              >
                <CustomSelect
                  style={{ borderRadius: 0 }}
                  placeHolder='Select Territory'
                  options={getOptions(requiredFields, 'mainAddress.territory')}
                  customClassName='no-border-radius-select'
                  dataTestId='territory-select'
                  id={`${elementData.formId}_territory`}
                  disabled={elementData.addressData.addressType === null}
                  handleChange={onSelectChangeHandler}
                  externalName='mainAddress.territory'
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify='space-between'>
            <Col span={8}>
              <Form.Item
                label='City'
                style={{ marginBottom: 15, paddingRight: 5 }}
                required={isRequired(requiredFields, 'mainAddress.city')}
              >
                <Input
                  style={{ borderRadius: 0 }}
                  data-testid='city-input'
                  name='city'
                  id={`${elementData.formId}_city`}
                  value={addresses?.[0]?.CITY ?? elementData.addressData.city}
                  disabled={elementData.addressData.addressType === null}
                  onChange={(event) => onInputChangeHandler(event.currentTarget.value, 'city')}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label='State'
                style={{ marginBottom: 15, paddingLeft: 5, paddingRight: 5 }}
                required={isRequired(requiredFields, 'mainAddress.state')}
              >
                <CustomSelect
                  style={{ borderRadius: 0 }}
                  options={getOptions(requiredFields, 'mainAddress.state')}
                  externalValue={
                    addresses?.[0]?.STATE ??
                    (typeof elementData.addressData.state !== 'string' &&
                    elementData.addressData.state?.id !== ''
                      ? elementData.addressData.state?.id
                      : 'Select State')
                  }
                  customClassName='no-border-radius-select'
                  dataTestId='state-select-element'
                  id={`${elementData.formId}_state`}
                  disabled={elementData.addressData.addressType === null}
                  handleChange={onSelectChangeHandler}
                  externalName='mainAddress.state'
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label='Country'
                style={{ marginBottom: 15, paddingLeft: 5 }}
                required={isRequired(requiredFields, 'mainAddress.country')}
              >
                <CustomSelect
                  style={{ borderRadius: 0 }}
                  options={getOptions(requiredFields, 'mainAddress.country')}
                  externalValue={
                    typeof elementData.addressData.country !== 'string' &&
                    elementData.addressData.country?.id !== ''
                      ? elementData.addressData.country?.id
                      : 'Select Country'
                  }
                  customClassName='no-border-radius-select'
                  dataTestId='country-select'
                  id={`${elementData.formId}_country`}
                  disabled={elementData.addressData.addressType === null}
                  handleChange={onSelectChangeHandler}
                  externalName='mainAddress.country'
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  );
};

export default CustomAddressForm;
