import { gql } from '@apollo/client';

export const CREATE_ONE_CONTACT = gql`
  mutation CreateContact($createContactInput: CreateContactInput!) {
    CreateContact(createContactInput: $createContactInput) {
      id
    }
  }
`;

export const UPDATE_MAIN_FIELDS = gql`
  mutation UpdateContactData($updateContactData: UpdateAllMainFieldsInput!) {
    UpdateContactData(UpdateContactData: $updateContactData) {
      title
      firstName
      lastName
      notes
      businessInfo {
        jobTitle
        company {
          name
          shortName
        }
      }
      personalInfo {
        mainEmail {
          emailType {
            name
            description
          }
        }
        mainPhone {
          countryCode
          areaCode
        }
        phones {
          id
          phone
          phoneType {
            id
            name
          }
        }
        emails {
          id
          emailType {
            name
          }
          email
        }
      }
    }
  }
`;

export const UPDATE_ONE_CONTACT = gql`
  mutation UpdateContact($updateContactInput: UpdateContactInput!) {
    UpdateContact(updateContactInput: $updateContactInput) {
      businessInfo {
        doNotEmail
        mainAddress {
          zipCode
          id
          city
          pobox
          formattedAddress
          address
          state {
            name
          }
          zipCode
          region {
            id
            name
          }
          territory {
            id
            name
          }
          continent {
            id
            name
          }
          country {
            id
            name
          }
        }
        addresses {
          id
          addressType {
            id
            name
          }
          region {
            id
            name
          }
          territory {
            id
            name
          }
          continent {
            id
            name
          }
          country {
            id
            name
          }
          state {
            id
            name
          }
          zipCode
          city
          pobox
          address
          formattedAddress
          isAddressInvalid
          geopositionLongitude
          geopositionLatitude
        }
        assistant
        company {
          name
          salesTeam {
            name
          }
          companyType {
            name
          }
        }
        context
        department
        mainEmail {
          id
          email
          emailType {
            id
            name
          }
        }
        emails {
          id
          email
          emailType {
            id
            name
          }
        }
        enabledForcedSync
        globallyVisible
        id
        isPrimary
        jobTitle
        referencedBy
        mainPhone {
          id
          areaCode
          countryCode
          extension
          phone
          phoneType {
            name
            id
          }
        }
        phones {
          id
          areaCode
          countryCode
          extension
          phone
          phoneType {
            name
            id
          }
        }
        manager
      }
      firstName
      groups {
        id
        name
      }
      products {
        id
        name
      }
      id
      notes
      title
      personalInfo {
        addresses {
          id
          address
          addressType {
            id
            name
          }
          city
          pobox
          continent {
            id
            name
          }
          country {
            id
            name
          }
          formattedAddress
          geopositionLatitude
          geopositionLongitude
          id
          region {
            id
            name
          }
          state {
            id
            name
          }
          territory {
            id
            name
          }
          zipCode
        }
        phones {
          id
          areaCode
          countryCode
          extension
          phone
          phoneType {
            id
            name
          }
        }
        emails {
          id
          email
          emailType {
            id
            name
          }
          id
        }
      }
      lastName
    }
  }
`;

export const REMOVE_ONE_CONTACT = gql`
  mutation RemoveContact($removeContactId: Int!) {
    RemoveContact(id: $removeContactId)
  }
`;

export const DELETE_CONTACTS_IN_BULK = gql`
  mutation ContactsBatchRemove($ids: [Int!]!) {
    ContactsBatchRemove(ids: $ids) {
      contact {
        id
        firstName
      }
      error {
        message
      }
    }
  }
`;
